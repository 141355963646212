.combobox {
    position: relative;
}

.combobox-selector {
    padding: 10px;
    border: 1px solid ;
    border-radius: .5rem;
    color: $text-foreground;
    cursor: pointer;
}

.combobox-wrapper{
    position: relative;
}

.combobox-options {
    position: absolute;
    border-radius: .5rem;
    width: 100%;
    max-height: calc(40vh + .5rem);
    background: white;
    overflow: auto;
    z-index: 1000000;
    box-shadow: rgba(113, 159, 206, 0.2) 0px 8px 24px;
}

.combobox-option{
    color:$text-foreground;
    &:hover{
        background: lighten($color: $text-foreground, $amount: 50);
    }
}