.user {
    // position: absolute;
    // top: 0;
    // left: 50%;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    //transform: translate(-50%, -50%);
    margin: auto;
    position: relative;


    background: $white;
    color: $primary;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(113, 159, 206, 0.2) 0px 8px 24px;

    &__pic {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        overflow: hidden;
    }
}

.edit {
    &__profile {
        display: inline-block;
        margin-top: .5rem;
        color: $medium-grey;
        font-size: .8rem;
        text-decoration: underline;
    }

    & input[type="file"] {
        display: none;
    }

    &__userPic {
        position: relative;
    }

    &__button {
        cursor: pointer;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: black;
        width: 30px;
        height: 30px;
        z-index: 2000;
        position: absolute;
        top: 70px;
        left: calc(50% + 25px);
        //transform: translate(-50%, -50%);

        & svg {
            width: 18px;
            fill: $white;
        }
    }


    &__links {
        width: 100%;
        margin: auto;

        @include responsive('screen') {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            max-width: 50rem;
            margin: auto;
            justify-items: center;
        }
    }

    &__input {
        width: 90%;
        margin: auto;

        @include responsive('screen') {
            width: 600px;
            margin: auto;
        }
    }

    &__card {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &__addcontact {
        display: flex;
        text-align: center;
        padding: .5rem;
        margin: auto;
        align-items: center;
        background: $button-color;
        border-radius: 1.5rem;
        box-shadow: 0px 10px 14px -8px #748ada;

        & img {
            width: 1rem;
            height: 1rem;
        }
    }
}

.card-margin {
    width: 95%;
    max-width: 50rem;
    margin: 0 auto;
}

.card-edit {
    background: $white;
    box-shadow: rgba(113, 159, 206, 0.2) 0px 8px 24px;
    border-radius: 24px;
    width: 95%;
    max-width: 50rem;
    margin: 0 auto;
}

// .contacts{
//     & .contact:not(:last-child){
//         border-bottom: 1px solid black;
//     }
// }

.list {
    display: none;

    @include responsive('screen') {
        display: grid;
        grid-template-columns: 3rem 1fr 1fr 1.2fr;

        & p {
            text-align: left;
            font-size: .8rem;
            overflow: hidden;
        }
    }

}

.contact-row {
    position: relative;
    display: flex;
    align-items: center;

    & .contact-remove {
        width: 1rem;
        height: 1rem;
    }
}

.contact {
    display: grid;
    grid-template-columns: 3rem 1fr;
    grid-template-areas:
        "ref supplier"
        "ref name"
        "ref email"
    ;
    border-bottom: .05rem solid rgb(181, 181, 181);
    overflow: hidden;
    flex: 1;
    cursor: pointer;

    @include responsive('screen') {
        display: grid;
        grid-template-columns: 3rem 1fr 1fr 1.2fr;
        grid-template-areas: "ref supplier name email";
        border-bottom: .05rem solid rgb(181, 181, 181);
    }

    & p {
        text-align: left;
        font-size: .9rem;
        overflow: hidden;
        line-height: 1.2rem;
    }

    & .contact-ref {
        grid-area: ref
    }

    & .contact-sup {
        grid-area: supplier
    }

    & .contact-name {
        grid-area: name
    }

    & .contact-email {
        color: $button-color;
        grid-area: email;
    }
}


.suppliers-btns {
    display: flex;
    gap: .5rem;
    overflow-y: auto;

    @include responsive('screen') {
        gap: 1rem;
    }
}

.supplier-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .8rem;
    padding: .5rem .7rem;
    border-radius: 5rem;
    white-space: nowrap;
    background-color: lighten($color: $text-foreground, $amount: 48);
    color: $text-foreground;


    &.active {
        background-color: $doneBg;
        color: $done;
    }
}

.supplier-count {
    display: grid;
    place-items: center;
    background-color: lighten($color: $text-foreground, $amount: 25);
    color: lighten($color: $text-foreground, $amount: 48);
    width: 1.2rem !important;
    height: 1.2rem !important;
    border-radius: 50%;
    font-size: .2rem;

    &.active {
        background-color: $done;
        color: $doneBg;
    }
}

.supplier-card {
    background-color: white;
    box-shadow: rgba(113, 159, 206, 0.2) 0px 8px 14px;
    border-radius: .4rem;
}

.supplier-wrapper {
    max-height: 0;
    overflow: hidden;
    transition: all .2s ease;
    padding-left: 1rem;

    @include responsive('screen') {
        padding-left: 6rem;
    }


    &.active {
        background-color: $bg-grey;
        max-height: 100rem;
        transition: all .3s ease;
    }

    &>*:nth-last-child(n+2) {
        border-bottom: .05rem solid rgb(181, 181, 181);
    }
}

.supplier-main {
    display: grid;
    align-items: center;
    grid-template-columns: 5rem 1fr;
    padding: .4rem 1rem
}

.supplier-contact-name {
    display: grid;
    grid-template-columns: 1fr;
    padding: .4rem .9rem .4rem 0;
    gap: .2rem;

    @include responsive('screen') {
        grid-template-columns: 20rem 1fr;
        padding: .5rem .9rem .5rem 0;
        gap: .5rem;
    }
}

.input-search {
    font-size: .5rem;
    all: unset;
    padding: .4rem .7rem;
    border-radius: 5rem;
    background-color: lighten($color: $text-foreground, $amount: 48);
}