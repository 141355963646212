.checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
  
    cursor: pointer;
  }

  
  .checkbox__input {
    width: 0;
    height: 0;
    opacity: 0;
  }
  
  .checkbox__input:checked + .checkbox__check {
    background: $green-secondary;
    stroke-dashoffset: 0;
  }
  
  .checkbox__check {
    border: 0.1rem solid $green-secondary;
    background: transparent;
    stroke: #f9f9f9;
    stroke-dasharray: 25;
    stroke-dashoffset: 25;
    stroke-linecap: round;
    stroke-width: 0.15rem;
  
    border-radius: 10rem;
  
    fill: none;
    }
  

//------------------------------------------------------------------


.supplier-contact{
    border-bottom: 1px solid $light-grey;
    padding: .5rem 0;
    position: relative;
    display: flex;
    align-items: flex-start;

    & input{
      margin-top: .1rem;
    }
}
// .supplier-contact{
//     border-bottom: 1px solid $light-grey;
//     padding: .5rem 0;
//     position: relative;
// }


//------------------------------------------------------------------

.round {
  position: relative;
  height: 100%;

  &__input[type="checkbox"] {
    visibility: hidden;
  }

  &__label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 1.2rem;
    position: absolute;
    width: 1.2rem;
    left: 0;
    top: 0
  }
}


.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: .3rem;
  left: .25rem;
  opacity: 0;
  position: absolute;
  top: .3rem;
  transform: rotate(-45deg);
  width: .6rem;
}


.round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}