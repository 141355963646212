.lifetest {
    flex: 1;
    display: flex;
    flex-direction: column;

    &__list {

        & .life-item {
            padding: .5rem 0;
            display: flex;
            align-items: center;
        }
    }

    & .life-title {
        flex: 1;

        &__data {
            margin-top: .1rem;

            & p {
                font-size: .7rem;
                line-height: .7rem;
            }

            & p:first-child {
                background: $input;
                border-radius: .2rem;
                padding: .2rem;
                margin-right: .5rem;
            }
        }
    }
}

.life-status {
    min-width: 80px;
    border-radius: .5rem;
    padding: .1rem .2rem;

    & p {
        font-size: .8rem;
    }

    &.status-done {
        background-color: lighten($color: $check, $amount: 30);

        & p {
            color: $green-secondary
        }
    }

    &.status-in-process {
        background-color: lighten($color: orange, $amount: 35);

        & p {
            color: rgb(221, 129, 0);
            font-size: .7rem;
        }
    }

    &.status-pending {
        background-color: lighten($color: $medium-grey, $amount: 37);

        & p {
            color: $medium-grey
        }
    }

}

.life-days {
    width: 80px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    & .day-on {
        width: 8px;
        height: 8px;
        margin: auto;
        border-radius: 50%;
        background-color: $check;
    }

    & .day-off {
        width: 8px;
        height: 8px;
        margin: auto;
        border-radius: 50%;
        background-color: rgb(219, 219, 219);
    }
}

.card-life {
    position: relative;
    background: $white;
    box-shadow: rgba(113, 159, 206, 0.2) 0px 8px 24px;
    border-radius: 12px;
    overflow: hidden;
    padding: .8rem 1rem;
}

.btn-add-life {
    width: 10rem;
    display: flex;
    justify-content: center;
    margin: 1rem auto;
    align-items: center;
    padding: .5rem 1rem;
    border-radius: 1.5rem;

    &.life-blue{
        background: $button-color;
        box-shadow: 0px 10px 14px -8px #748ada;
        & p { color: $white; }
    }

    &.life-red{
        background: transparent;
        box-shadow: 0px 4px 10px -8px $danger;
        border: 1px solid $danger;
        & p { color: $danger; }
    }

    & img {
        width: 1.5rem;
        margin-right: .5rem;
    }
}

.modal-conditions {
    display: flex;
    flex-wrap: wrap;

    &__button {
        width: auto;
        background: $dark-grey;
        padding: .3rem .6rem;
        border-radius: 1.5rem;
        color: #fff;

        div {
            margin-left: .8rem;
        }
    }

    &__item {
        width: auto;
        background: $input;
        padding: .3rem .6rem;
        border-radius: 1.5rem;
        font-size: .8rem;
        font-weight: bold;
        color: $dark-grey;
    }
}

.edit-day {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    & button {
        margin: .1rem .2rem 0 0;
        width: 18px;
        height: 18px;
    }
}

.life-score{
    padding: 0 .5rem;
    border-radius: 1rem;
    & p{font-size: .9rem;}
}

.remove-life{
    display: block;
    margin-top: auto;
    width: 100%;
    padding: .7rem 1rem;
    text-align: center;
    border-radius: .7rem;
    border: 1px solid rgb(235, 40, 40);
    color: rgb(235, 40, 40);
    background: rgb(255, 230, 230);
    transition: all .3s ease;
    &:hover{background: rgb(255, 200, 200);}

    @include responsive('screen'){
        width: 50%;
        align-self: center;
    }
}

.delete-day{
    display: flex;
    margin: auto;
    align-items: center;
    cursor: pointer;
    & p{color: $danger; text-decoration: underline;}
}

.life-btns{
    display: flex;
    gap: .5rem;
    margin: auto;
}