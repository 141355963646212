.intake #cvsFile {
    display: none;
}

.intake {
    &__container {
        position: relative;
        display: flex;
        flex: 1;
    }

    &__grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;

        @include responsive('screen') {
            grid-template-columns: 1fr 1fr;

        }
    }

    &__list {
        display: grid;
        grid-template-columns: 1fr;
        gap: .75rem;

        @include responsive('screen') {
            grid-template-columns: repeat(2, 1fr);

        }
    }

    &__fullreport input:focus,
    textarea:focus,
    select:focus {
        outline: .12rem solid $primary !important;
        border-radius: .5rem;
    }

    &__inCharge {
        background-color: $clear-green;
        position: absolute;
        top: 0;
        left: 0;
        padding: .5rem;
        display: grid;
        place-items: center;
        border-bottom-right-radius: .5rem;
    }

    &__card {
        position: relative;
        padding: 1rem;
        background: $white;
        box-shadow: rgba(113, 159, 206, 0.2) 0px 8px 24px;
        border-radius: 12px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: grid;

        @include responsive('screen') {
            margin-bottom: 0;
        }
    }

    &__input {

        display: grid;
        grid-auto-flow: column;
        grid-template-columns: repeat(8, 1fr);
        align-items: center;
        margin-bottom: .5rem;

        & .span-modal {
            grid-column: span 4;
        }

        & label {
            font-size: .9rem;
        }

        & .label-title {
            font-size: 1rem;
        }

        & label,
        .label-title {
            grid-column: span 4;
            padding-right: .5rem;
        }

        & .input-text {
            grid-column: span 4;
            padding: .5rem;
        }

        & input {
            grid-column: span 4;
            padding: .7rem .5rem;
            border: none;
            background: $input;
            border-radius: .5rem;
            position: relative;
            right: 0;
            max-height: 2.3rem;

            &:focus-within {
                @include input-focus;
            }
        }

        & .input-date {
            width: 100%;
        }
    }

    &__left {
        border-right: 1px solid $medium-grey;
        padding-right: 1rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        & .p-ref {
            font-weight: bold;
            font-size: .9rem;
        }

        & p {
            font-size: .8rem;
            line-height: 1rem;
        }
    }

    &__right {
        padding: 0 1rem;

        & p {
            font-size: .7rem;
            line-height: 1rem;
        }
    }
}

.boton-intake {
    display: flex;
    flex-direction: column;
    align-items: center;

    & a {
        width: 50px;
        height: 50px;
        padding: .5rem;
    }

    & button {
        width: 40px;
        height: 40px;
        padding: .4rem;
    }

    & a,
    button {
        display: inline-block;
        background: $light-grey;
        border-radius: 50%;

        svg {
            fill: $primary;
        }
    }
}

.link {
    cursor: pointer;
    text-decoration: underline;
    color: $button-color;
    font-size: .9rem;
}



.intake-select {
    //     -webkit-appearance: none;
    //    -moz-appearance: none;

    min-height: 33px;
    border: none;
    background: $input;
    padding: 0 .5rem;
    border-radius: .5rem;

    &:focus {
        border: none !important;
        outline: none !important;
    }
}

.inCharge-bookmark {
    background-color: $clear-green;
    width: 1.3rem;
    height: 1.3rem;
    display: grid;
    place-items: center;
    border-radius: 50%;
}

.input-label{
    font-size: .9rem;
}