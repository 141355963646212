
.multisteps {
    display: grid;
    // grid-template-columns: 1fr;
    grid-template-areas: 
        "steps steps"
        "previous next";
    gap: 1rem;
    // width: 100%;

    @include responsive('screen'){
        grid-template-columns: 1fr auto 1fr;
        grid-template-areas: 
        "previous steps next";
        place-items: center;
        width: 100%;
    }

    &__steps {
        grid-area: steps;
        display: grid;
        grid-template-columns: 1.5rem 2rem 1.5rem 2rem 1.5rem 2rem 1.5rem;
        place-items: center;
        justify-content: center;

        & .step-line {
            width: 100%;
            height: .1rem;
            background-color: lighten($color: $inactive, $amount: 30);

            &.active{
                background-color: $check;
            }
        }
        & .step-item {
            width: 1.5rem;
            height: 1.5rem;
            display: flex;
            justify-content: center;
            background-color: darken($color: $done, $amount: 5);
            border-radius: 50%;

            & p{ color: #fff; font-size: .7rem;}

            &.active{
                background-color: $check;
            }
            &.deactive{
                background-color: lighten($color: $inactive, $amount: 30);
            }
        }
    }
    &__action {

        min-width: 6rem;
        border: 1px solid $done;
        border-radius: 5rem;


        & p {font-size: .8rem; color: $done;}

        &.previous{ justify-self: flex-start;}
        &.next{ 
            justify-self: flex-end;

            &.next-blue{
                border-color: $button-color;
                background-color: $button-color;
                & p{color: #fff;}
            }
            &.next-blue-disabled{
                border-color: $button-color-disabled;
                background-color: $button-color-disabled;
                & p{color: #fff;}
            }
        }
    }
}