.photo{
    display: grid;
    place-items: center;
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    background-color: $input;
    border-radius: .5rem;
    cursor: pointer;

    &.alignTop{
        align-self: flex-start;
    }
    &.photo-active{
        background-color: $doneBg;
    }
    &.photo-inactive{
        background-color: $input;
    }
}

.photo-empty-space{
    min-width: 2rem;
    margin-left: .5rem;
}

.gallery-photos{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;

    &__container{
        width: 100%;
        aspect-ratio: 1;
        overflow: hidden;
        position:relative;

        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        & .photo-tag{
            position: absolute;
            bottom: .2rem;
            left: .2rem;
            padding: .1rem .4rem;
            border-radius: 2rem;
            background: rgba(0, 0, 0, .4);

            & p{
                color: white;
                font-size: .5rem;
                font-weight: 500;
                line-height: .7rem;
                
            }

        }
    }
}

.pallgrow-item {
    margin-right: 2.5rem;
}

.photo-tag-pdf{
    position: absolute;
    bottom: .2rem;
    left: .2rem;
    padding: .1rem .6rem;
    border-radius: 2rem;
    background: rgba(0, 0, 0, .4);

    & p{
        color: white;
        font-size: .7rem;
        font-weight: 500;
        line-height: .8rem;
        
    }

}