
// .icon-input{
//     padding: .7rem .5rem;
//     border: none;
//     background: #dfe1ea;
//     border-radius: .5rem;
//     position: relative;
//     max-height: 2.3rem;
//     flex: 1;

//     & input{
//         width: 100%;
//         all: unset;
//         flex: 1;
//     }
// }

.icon-input {
    padding: .7rem .5rem;
    border: none;
    background: #dfe1ea;
    border-radius: .5rem;
    position: relative;
    max-height: 2.3rem;
    flex: 1;
    display: flex;
    align-items: center;
    cursor: text;
  
    & input {
      width: 100%;
      all: unset;
      flex: 1;
    }
  
    &:focus-within {
        @include input-focus;
    }
  }