.modal {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    inset: 0;
    background: rgba($color: $black, $alpha: .8);
    z-index: 9000;

    &__card {
        position: relative;
        border-radius: 1rem;
        min-height: 90px;
        width: 90%;
        max-width: 450px;
        background: $white;
        padding: 1.5rem;
        overflow-y: auto;
        max-height: 95%;

        &.no-overflow {
            overflow:inherit;
        }

        &.mobile-version{
            max-width: 800px;
        }
    }

    &__cardPDF {
        background: $white;
        width: 90%;
        border-radius: 1rem;
        max-height: 90%;
        overflow-y: auto;
        padding: 1rem 1rem 1.5rem 1rem;

        @include responsive('screen') {
            width: auto;
            min-width: 25rem;
            max-width: 95%;
        }

    }


    &__botones {
        display: flex;
        justify-content: center;
        column-gap: .5rem;

        & .modal-btn {
            width: 6rem;
            padding: .8rem 0;
            border-radius: .5rem;
            flex: 1;
        }

        & .btn-close {
            background: none;
            border: 1px solid $red;
            color: $red;
            & p{color: $red}
        }

        & .btn-add {
            background: $done;
            color: #fff;
            & p{color: #fff;}
        }
        & .btn-disabled {
            cursor: default;
            background: rgb(217, 236, 217);
            color: $done;
            & p{color: $done;}

        }
        & .btn-muted {
            background: rgb(225, 225, 225);
        }

        & .btn-remove {
            background: none;
            border: 1px solid $red;
            color: $red;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        // width: 100;

        & label {
            margin-bottom: .2rem;
        }

        & input {
            background: $input;
            border: none;
            border-radius: .5rem;
            width: 100;
        }
        & .input-outline {
            background: white;
            border: 1px solid $dark-grey;
            border-radius: .5rem;
            width: 100;
        }
        & .input-disabled {
            background: rgb(208, 210, 211);
            border: none;
            border-radius: .5rem;
            width: 100;
        }
        & .input100 {
            background: $input;
            border: none;
            border-radius: .5rem;
            width: 100%;
        }

        & input[type=number], input[type=text]{
            padding: .8rem .5rem;
        }

        & .modal-label {
            font-weight: bold;
            font-size: 1.2rem;
        }
    }
}

.modal-add {
    background: none;
    padding: .4rem 0;
    display: flex;
    align-items: center;

    & div {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: $green;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    & .red {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: $redBg;
        display: flex;
        border: 1px solid $red;
        align-items: center;
        justify-content: center;
        color: $red;
    }

}


.next-prev {
    position: absolute;
    width: 95%;

    display: flex;
    justify-content: space-between;

    & img {
        width: 1.8rem;
        height: 1.8rem;
        margin: 0;
        cursor: pointer;
    }

}

.index-float {
    position: fixed;
    bottom: 1rem;
    z-index: 12000;
    display: flex;
    justify-content: center;
    align-items: center;

    & p {
        color: white;
    }

    // width: 100px;
    // height: 100px;
    // background: red;
}

.delete-img {
    background-color: #FF4D4D;
    border-radius: 1.5rem;
    color: #FFDBDB;
    padding: .3rem 1rem;
    font-size: 1rem;

    position: fixed;
    // z-index: 9500;
    left: 1.5rem;
    top: 1rem;

    transition: all .2s ease;

    &:hover {
        box-shadow: 0px 10px 14px -8px rgb(218, 116, 116);
    }
}


.modal-images {
    display: grid;
    grid-template-columns: repeat(3, minmax(auto, 7rem));

    @include responsive('screen') {
        grid-template-columns: repeat(5, minmax(auto, 7rem));
    }
}

.modalPDF {

    cursor: pointer;

    &__container {
        position: relative;
        height: 100px;

        @include responsive('screen') {
            height: 7rem;
        }

        & .box-checked {
            position: absolute;
            width: 1rem;
            height: 1rem;
            padding: .2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            top: .5rem;
            left: .5rem;
        }

        & .check-on {
            background: $check;
            border: 1px solid $check;
            box-shadow: rgba(0, 255, 64, 0.2) 0px 8px 12px;
        }

        & .check-off {
            background: none;
            border: 1.5px solid $white;
        }
    }

    & input[type=checkbox] {
        opacity: 0;
        width: 0;
        height: 0;
        display: none;
    }

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.img-trans {
    opacity: .4;
}

.multiColumn {
    column-count: 2;
}

.column-index {
    width: 30px;
    font-size: .8rem;
    color: #a4a4a4;
}