.row{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

@for $i from 1 through 12 {
    .col-#{$i}{
        width: 100% / 12 * $i;

        @include responsive(tablet){
            width: 100%
        }
    }
}

.grid{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
}
.grid--space{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: .2rem;
    align-items: center;
}

.report-title{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.span-1{grid-column: span 1;}
.span-2{grid-column: span 2;}
.span-3{grid-column: span 3;}
.span-4{grid-column: span 4;}
.span-5{grid-column: span 5;}
.span-6{grid-column: span 6;}
.span-7{grid-column: span 7;}
.span-8{grid-column: span 8;}
.span-9{grid-column: span 9;}
.span-10{grid-column: span 10;}
.span-11{grid-column: span 11;}
.span-12{grid-column: span 12;}

.grid-table{
    & .table{
        border-bottom: .5px solid $light-grey;
        padding: .2rem 0;
    }
}

.table{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.select-date .react-datepicker-wrapper{
    width: 100%;
}

.grid-query{
    display: grid;
    grid-template-columns: 1fr;
    gap: .5rem;

    @include responsive("screen"){
        grid-template-columns: repeat(2, 1fr);
        gap: .5rem;
    }

    &.grid-gap-1{ gap: 1rem; }
    &.grid-gap-2{ gap: 1.5rem; }
    &.grid-gap-3{ gap: 2rem; }
    &.grid-gap-4{ gap: 2.5rem; }
}

.grid-array{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: .5rem;
}

.contacts-title{
    flex: 1;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;

    @include responsive("screen"){
        grid-template-columns: 1fr 3fr;
    }
}

.grid-one{
    display: grid;
    grid-template-columns: 1fr;
    gap: .5rem;
}

.grid-counter{
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    gap: .5rem;
}

.grid-two{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}