// BOTONES
.btn-primary {
    display: inline-block;
    padding: .75rem 2rem;
    outline: none;
    //border-radius: .5rem;
    border-radius: 2rem;
    cursor: pointer;
    background-color: $primary;
    color: $white;
    //box-shadow: 0 8px 16px 0 rgba(58, 58, 58, 0.3);
    transition: all .3s ease;

    & i {
        font-size: 1rem;
    }

    &:hover {
        background-color: $primary-hover;
        box-shadow: none;
    }

    &.outline {
        background-color: transparent;
        border: 1px solid $primary;
        color: $primary;

        &:hover {
            border: 1px solid transparent;
            background-color: $primary;
            color: $white;
        }
    }

    &.outline-2 {
        background-color: transparent;
        border: 1px solid $green;
        color: $green;

        &:hover {
            border: 1px solid transparent;
            background-color: $primary;
            color: $white;
        }
    }
}

.btn-block {
    width: 100%;
    text-align: center;

    &__80 {
        width: 80%;
        text-align: center;
    }
}

.btn-center {
    display: flex;
    justify-content: center;
    margin: auto;
}

.desactive {
    background-color: $silver;
    border: 1px solid transparent;
    color: $medium-grey;

    &:hover {
        background-color: $silver;
        color: $medium-grey;
    }
}

.return {
    width: 30px;
    position: absolute;
    top: 25px;
    left: 25px;
}

/* .btn-logout{

} */

.btn-copy {
    padding: 0 .5rem;
    border: 1px solid $button-color;
    background-color: transparent;
    border-radius: .5rem;
    box-shadow: 0 4px 10px 0 rgba(50, 101, 197, 0.2);

    & p {
        font-size: .8rem;
        color: $button-color;
    }

    &.blue {
        padding: .2rem 1rem;
        background-color: $button-color;
        border: none;
        border-radius: 1rem;

        & p {
            font-size: .8rem;
            color: white;
        }
    }
}

.btn-lifetest {
    padding: .2rem .5rem;
    border: 1px solid $button-color;
    background-color: transparent;
    border-radius: .5rem;

    & p {
        font-size: .8rem;
    }

    &.active {
        background-color: $done;
        border: none;

        & p {
            color: #fff;
        }
    }

    &.deactive {
        background-color: transparent;
        border: 1px solid $done;

        & p {
            color: $done;
        }
    }
}

.btn-user-selected {
    padding: 1rem .5rem;
    height: 1.6rem;
    background-color: #dfeeff;
    border-radius: 1rem;
    overflow: hidden;

    & p {
        font-size: .8rem;
        color: $button-color;
    }


    &.blue {
        background-color: $button-color;

        & p {
            font-size: .8rem;
            color: white;
        }
    }
}

.btn-add-user {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.7rem;
    width: 1.7rem;
    background-color: $button-color;
    border-radius: 50%;
}

.btn-x {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.7rem;
    height: 1.7rem;
    background-color: black;
    border-radius: 1rem;
    box-shadow: 0 4px 10px 0 rgba(50, 101, 197, 0.2);
}

.btn-mainData {
    // background-color: $button-color;
    border: 1px solid $button-color;
    padding: .3rem;
    border-radius: 12px;
}

.btn {
    display: inline-block;
    padding: .75rem 2rem;
    outline: none;
    border-radius: 2rem;
    // cursor: pointer;
    background-color: $greenMain;
    color: $white;
    transition: all .3s ease;

    &.btn-small {
        padding: .4rem 1.2rem;
    }

    &:hover {
        background-color: darken($color: $greenMain, $amount: 10);
    }

    &.btn-blue {
        background-color: $button-color;

        &:hover {
            background-color: darken($color: $button-color, $amount: 10)
        }
    }

    &.btn-disabled {
        background-color: $inactive;
    }
}

.btn-add-rest {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 5rem;
    min-width: 2.5rem;
    background: $button-color;
    border: 1px solid $button-color;
    border-radius: .5rem;
    color: #fff;

    @include responsive("screen") {
        max-width: 5rem;
        min-width: 4rem;
    }

    &.green {
        background: lighten($color: $doneBg, $amount: 5);
        border: 1px solid $done;
        color: $done;
    }

    &.red {
        background: $redBg;
        border: 1px solid $red;
        color: $red;
    }

    &.orange {
        background: lighten($color: $minor, $amount: 30);
        border: 1px solid $minor;
        color: $minor;
    }
}

.btn-round {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    background-color: $button-color;
    border-radius: 50%;
    color: #fff;
    font-size: 1.5rem;
}

.btn-input {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $button-color;
    cursor: pointer;
    flex: 1;
    width: 100%;
    height: 2.2rem;
    border-radius: .5rem;
    color: #fff;
    transition: all .3s ease;

    &.btn-small {
        width: auto;
        padding: 0 .5rem;
    }

    &:hover {
        background: darken($color: $button-color, $amount: 10);
        box-shadow: none !important;
    }

    &.green {
        background: $greenMain;

        &:hover {
            background: darken($color: $greenMain, $amount: 10);
        }
    }

    &.blue {
        background: $button-color;

        &:hover {
            background: darken($color: $button-color, $amount: 10);
        }
    }
}

.btn-pallet-remove {
    aspect-ratio: 1;
    padding: .4rem;
    overflow: hidden;
    background-color: $red;
    border-radius: 50%;
    color: $redBg2;
    z-index: 10;
}

.btn-add-any {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $button-color;
    border-radius: .5rem;
    color: #fff;
    padding: .2rem .5rem;
}

.btn-new {
    font-size: .8rem;
    padding: .5rem .7rem;
    border-radius: 1rem;
    background-color: $button-color;
    color: white;

    .muted {
        background-color: lighten($color: $text-foreground, $amount: 48);
        color: $text-foreground;
    }
}