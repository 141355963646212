$pallHeight: 2.5rem;
$sampleHeight: 1.2rem;

.table-column-label {
    // background: red;
    width: 50%;
    padding-right: .2rem;
    align-self: flex-start;

    @include responsive('screen'){
        width: 50%;
    }

    &.column-label{
        margin-top: 1rem;
    }
}

.label-pallgrow {
    display: flex;
    align-items: center;
    font-size: .9rem;
    line-height: .9rem;

    height: $pallHeight;
}

.table-column-input {
    flex: 1;
    overflow-y: auto;
}

.table-column-photo {
    width: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: $sampleHeight;
    align-self: flex-start;

    // @include responsive('tablet'){
    //     margin-top: 1rem;
    // }
}

.input-pallgrow {
    display: flex;
    gap: .2rem;
    height: $pallHeight;
    padding: .2rem 0;

    &.samples{
        height: $sampleHeight;
    }

    & input {
        min-width: 3rem;
        flex: 1 1 3rem;
        padding: .2rem;
        border: 1px solid #d8d8d8;
        border-radius: 0.5rem;
    }

    & span{
        min-width: 3rem;
        flex: 1 1 3rem;
        font-size: .6rem;
        text-align: center;
    }
}

.weight-item{
    width: 50%;
    display: flex;
    align-items: center;
    gap: .2rem;
}

.input-general{
    border: 1px solid #d8d8d8;
    border-radius: 0.5rem;
    padding: 0.5rem;
    width: 100%;
}

.photo-pallgrow{
    height: $pallHeight;
    align-items: center;
    display: flex;
}

.add-sample-container{
    width: 50%;
    margin-left: auto;
}

.add-sample{
    display: flex;
    gap: .5rem;
}


/*  */