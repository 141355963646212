
.discrepancy{

   display: grid;
   grid-template-columns: 1fr;
   gap: .5rem;

   @include responsive('screen'){
      grid-template-columns: 1fr 1fr;
   }

 &__container{
   position: relative;
    display: flex;
    align-items: center;
    background: white;
    border-radius: .5rem;
    padding: .5rem;
    overflow: hidden;
    cursor: pointer;
    
   //  &::before{
   //    content: "";
   //    position: absolute;
   //    border-left: 5px solid $red;
   //    width: 1rem;
   //    height: 100%;
   //    left: 0;
   //  }
 }
 &__label{
   color: $red;
 }

}

.btn-remove-trash{
   background: $redBg;
   width: 2rem;
   height: 2rem;
   display: grid;
   place-items: center;
   border-radius: 5rem;
}

.two-columns{
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    @include responsive('screen') {
      grid-template-columns: 1fr 1fr;
    }
}