.reports {

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__search {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--circle {
            background: $light-grey;
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            padding: .5rem;

            & svg {
                fill: $primary;
            }
        }
    }

    &__list {
        @include responsive('screen') {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: .75rem;
        }
    }
}

.card {
    position: relative;
    background: $white;
    box-shadow: rgba(113, 159, 206, 0.2) 0px 8px 24px;
    height: 70px;
    border-radius: 12px;
    overflow: hidden;
    display: flex;

    &__image {
        width: 100px;
    }

    &__content {
        padding: .4rem .5rem;
    }

    &__body {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
    }

    &__title h4 {
        font-size: .8rem;
        line-height: .6rem;
    }

    &__text {
        font-size: .7rem;
        line-height: .6rem;
    }
}

.remove {
    color: $black;
    background: none;
    position: absolute;
    top: .5rem;
    width: 20px;
    right: .25rem;
    z-index: 900;

    & img {
        width: 12px;
    }
}

.window-card {
    min-width: 8rem;
    background: #fff;
    box-shadow: rgba(113, 159, 206, 0.4) 0px 8px 24px;
    z-index: 1000;
    padding: .5rem .7rem;
    position: absolute;
    border-radius: .5rem;
    top: .5rem;
    right: 1.5rem;
    

    & a {
        display: inline-block;
    }

    & p,
    a {
        cursor: pointer;
        color: rgb(121, 121, 121);
    }

    & p:hover,
    a:hover {
        color: $text;
    }
}

.popup-window {
    min-width: 8rem;
    background: #fff;
    box-shadow: rgba(113, 159, 206, 0.4) 0px 8px 24px;
    z-index: 1000;
    padding: .5rem .7rem;
    position: absolute;
    border-radius: .5rem;
    top: 0;
    right: 2rem;
    

    & a {
        display: inline-block;
    }

    & p,
    a {
        cursor: pointer;
        transition: all .3s ease;
        color: rgb(121, 121, 121);
    }

    & p:hover,
    a:hover {
        color: $text;
    }
}

.remove-circle {
    z-index: 1500;
    position: absolute;
    color: $black;
    background: none;
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    top: .5rem;
    right: .5rem;
    background-color: $light-grey;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & svg {
        fill: $black;
        width: .6rem;
    }
}

.team-icon {
    z-index: 1500;
    position: absolute;
    width: 1.2rem;
    height: 1.2rem;
    right: .7rem;
    bottom: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.card-report {
    background: $white;
    box-shadow: rgba(113, 159, 206, 0.2) 0px 8px 24px;
    // min-height: 100px;
    border-radius: 12px;
    overflow: hidden;

    & .pallet-item {
        color: $green;
    }
}

.d-input {
    display: flex;
    align-items: center;
    width: 100%;

    & input {
        flex: 1;
    }

    & button {
        width: 30px;
        height: 30px;
        border: 1px solid $primary;
        background: none;
        color: $primary;
        border-radius: 50%;
        margin-left: .5rem;
    }
}

.d-input-btn{
    display: grid;
    place-items: center;
    background-color: $button-color;
    color: white;
    aspect-ratio: 1;
    height: 2.2rem;
    border-radius: .5rem;
}

.pallet-ref {
    // background: $bg-grey;
    padding: .5rem;
    border-radius: .5rem 0;
    display: flex;
    align-items: center;

    & span,
    .index-number {
        font-size: .7rem;
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $dark-grey;
        // border: .5px solid $dark-grey;
        border-radius: 50%;
        color: white;
    }
}

.index-number {
    font-size: .7rem;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $dark-grey;
    border-radius: 50%;
    color: white;

    &.index-deactivate{
        background: $mute;
    }
}

.grid-img {
    display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    // grid-auto-rows: 110px;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;



    @include responsive('screen') {
        // grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        // grid-auto-rows: 130px;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 1fr;
    }

}

.img-g {
    width: 100%;
    aspect-ratio: 1;
    cursor: pointer;
    overflow: hidden;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

/* model */

.model {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: $black, $alpha: 0.95);
    visibility: hidden;
    opacity: 0;
    //transform: scale(0);
    overflow: hidden;
    z-index: 9000;

    transition: opacity .4s ease, visibility .4s ease, transform .3s ease-in-out;
}

.model.open {
    visibility: visible;
    opacity: 1;
    //transform: scale(1);
}

.model img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    line-height: 0;
    margin: 0 auto;
}

.model.open .svg-icon,
.model .svg-icon {
    position: fixed;
    z-index: 9500;
    width: 15px;
    right: 1.5rem;
    top: 1.5rem;
}

.card-pallet {
    position: relative;
    background: $white;
    box-shadow: rgba(113, 159, 206, 0.2) 0px 8px 24px;
    border-radius: 12px;
    padding: 1rem;
    // overflow: hidden;

    &.deactive-pallet{
        // background: $inactiveBg;
        // background: $bgColor;
        box-shadow: none;
        background: lighten($color: $input, $amount: 5);
        border: 1px solid lighten($color: $mute, $amount: 10);
        // border: 1px solid darken($color: $input, $amount: 20);
    }
}

.upload-btn {
    display: none;
}

.pallets-container {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 1rem;

    @include responsive('screen') {
        grid-template-columns: repeat(2, 1fr);
    }

    &.life-columns {
        @include responsive('screen') {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

.select-wraper {
    width: 100%;
    min-height: 33px;
    border: none;
    background: $input;
    padding: 0 .5rem;
    border-radius: .5rem;
}

.score-wraper {
    display: flex;
    align-items: center;

    &.column {
        flex-direction: column;
    }

    & p {
        flex: 1;
    }

    &.wraper-up {
        align-items: flex-start;
    }

    & select {
        width: 50%;
        min-height: 33px;
        border: none;
        background: $input;
        padding: 0 .5rem;
        border-radius: .5rem;
    }

    & .full {
        width: 100%;
        min-height: 33px;
        border: none;
        background: $input;
        padding: 0 .5rem;
        border-radius: .5rem;
        flex: 1;
    }
}

.score-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0.75rem;
    column-gap: 0.75rem;
    padding: 0 0.5rem;

    // @include responsive('screen'){
    //     grid-template-columns: repeat(2, 1fr);
    // }

    & input {
        width: 100%;
        border: 1px solid #d8d8d8;
        border-radius: 0.5rem;
        padding: 0.5rem;
    }

    & select {
        width: 100%;
    }
}

.score-header {
    background: #dff8ea;
    border: 1px solid #78d2a0;
    border-radius: 1rem;
    padding: .1rem .5rem;

    & p {
        font-weight: 700;
        font-size: .8rem;
    }
}

.pallet-comments {

    & textarea {
        max-width: 100%;
        min-width: 100%;
        min-height: 10rem;
        border: none;
        border-radius: .5rem;
        background: $input;
        padding: .5rem .8rem;
        line-height: 1.2rem;

        @include responsive('screen') {
            min-height: 5rem;
        }
    }

    & select {
        width: 100%;
        min-height: 33px;
        border: none;
        background: $input;
        padding: 0 .5rem;
        border-radius: .5rem;
    }
}

.select-input {

    & select,
    .select-card {        
        width: 100%;
        min-height: 33px;
        border: none;
        background: $white;
        padding: 0 .5rem;
        -webkit-box-shadow: 0px 6px 13px 0px rgba(113, 159, 206, 0.2);
                box-shadow: 0px 6px 13px 0px rgba(113, 159, 206, 0.2);
        border-radius: .5rem;
        cursor: pointer;

        &.select-border {
            padding: .7rem .5rem;
            border: 1px solid $primary;
        }
    }
}

/* SCORE */

.score-btn {
    background: none;
    width: 130px;
    justify-self: center;
}

.score-container {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    border-top: 1px solid $input;
}

.score {
    width: 100%;
    // min-width: 120px;
    padding: .2rem .5rem;
    border-radius: 1rem;
    text-align: center;
    font-weight: 700;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-size: .8rem;
    }
}


.score-0,
.grade-0,
.action-0 {
    background: $score-0;
    color: black;
}

.score-1 {
    background: $score-1;

    & p {
        color: white;
    }
}

.score-2,
.grade-1,
.action-1,
.action-2 {
    background: $score-2;

    & p {
        color: white;
    }
}

.score-3,
.grade-2 {
    background: $score-3;

    & p {
        color: white;
    }
}

.score-4,
.grade-3,
.action-3 {
    background: $score-4;

    & p {
        color: black;
    }
}

.score-5,
.grade-4,
.action-4,
.action-5 {
    background: $score-5;

    & p {
        color: black;
    }
}

.score-6,
.grade-5,
.action-6 {
    background: $score-6;

    & p {
        color: black;
    }
}

.score-7,
.grade-6,
.action-7 {
    background: $score-7;

    & p {
        color: white;
    }
}

.score-8 {
    background: $score-8;

    & p {
        color: white;
    }
}


.circle-score {
    position: absolute;
    bottom: 12px;
    right: 10px;
    border-radius: 50%;
    width: 10px;
    height: 10px;
}

.circle-life {
    border-radius: 50%;
    width: 7px;
    height: 7px;
}

.circle-score0 {
    background: $score-0;
}

.circle-score1 {
    background: $score-1;
}

.circle-score2 {
    background: $score-2;
}

.circle-score3 {
    background: $score-3;
}

.circle-score4 {
    background: $score-4;
}

.circle-score5 {
    background: $score-5;
}

.circle-score6 {
    background: $score-6;
}

.circle-score7 {
    background: $score-7;
}

.circle-score8 {
    background: $score-8;
}

.circle-score-no {
    background: white;
    border: .1rem solid $input;
}

/* REMOVE */

.remove-pallet {
    position: absolute;
    top: 18px;
    right: 18px;
    background: none;
    font-size: .8rem;
    color: $red;

    &.pre-data {
        color: $dark-grey;
        text-decoration: underline;
    }

    &.badge-remove {
        background: $redBg2;
        padding: .2rem .5rem;
        border-radius: 5rem;
    }
}

.remove-btn {
    background: $redBg2;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 5rem;
}

.btn-center {
    display: flex;
}

.btn-exports {
    display: flex;
    width: auto;

    padding: .5rem 1.5rem;
    border-radius: 2rem;

    transition: all .3s ease;

    & img {
        height: 25px;
        margin-right: .5rem;
    }
}


.green {
    background: $excel;

    & p {
        color: $white;
    }

    &:hover {
        box-shadow: 0px 7px 14px -8px rgba(23, 56, 30, 0.6);
    }
}

.red-pdf {
    background: #F40F02;

    & p {
        color: $white;
    }

    &:hover {
        box-shadow: 0px 10px 14px -8px rgb(218, 116, 116);
    }
}

.red-pdf-disabled {
    background: #ffa9a5;

    & p {
        color: $white;
    }
}

.share-white {
    background: $white;

    border: 1px solid $green-secondary;

    & p {
        color: $green-secondary
    }

    &:hover {
        box-shadow: 0px 7px 14px -8px rgba(96, 138, 105, 0.6)
    }
}

.share-blue {
    background: $button-color;
    border: 1px solid $button-color;

    & p {
        color: $white;
    }

    &:hover {
        box-shadow: 0px 7px 14px -8px rgba(96, 102, 138, 0.6);
    }

    &.blue-disabled {
        background: $button-color-disabled;
        border: 1px solid $button-color-disabled;
    }
}

.filter {
    width: 2rem;
    height: 2rem;
    padding: .4rem;
    background: $white;
    border-radius: .4rem;
    @include shadow;
    @include flex-center;
    transition: all .2s ease;

    &:hover {
        background: $input;
    }
}

.pdf-container {
    display: flex;
    justify-content: center;
}

.pdf-container-phone {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

#comments,
.comments-report {
    font-family: $font-text;
    white-space: pre-wrap;
}

.back-chevron {
    margin-bottom: .17rem;
    margin-right: .1rem;
}

.symbols {
    margin: .25rem .35rem 0 .35rem;
    color: rgb(176, 176, 176);
}

.main-info {
    @include responsive('screen') {
        width: 50%;
    }

    &__item {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
    }
}

.pallet-prereport {
    border: 0.5px solid $dark-grey;
    border-radius: .5rem;
    padding: .8rem;
    position: relative;

    &>div:first-child {
        display: flex;
        position: absolute;
        top: -.7rem;
        left: .7rem;
        background: $dark-grey;
        color: #fff;
        padding: .2rem .8rem;
        border-radius: 1rem;
    }
}

.prereport-edit-btn {
    width: 1rem;
    background: none;
}

.input-columns {
    display: inline-block;
    columns: 2;

    &__input {
        display: flex;
        align-items: center;

        & input {
            width: 100%;
        }

        & p {
            width: 1.8rem;
        }
    }
}

.tempPhoto {
    width: 10rem;
    height: 10rem;
    position: relative;
    margin: auto;
    // overflow: hidden;

    &__img {
        border-radius: 1.5rem;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__close {
        position: absolute;
        border-radius: 10rem;
        top: -.4rem;
        right: -.4rem;
        width: 1.5rem;
        height: 1.5rem;
        padding: .4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: black;
    }
}

.team-info {
    display: flex;
    width: fit-content;
    border-radius: 5rem;
    padding: .15rem .6rem;

    // &.name {
    //     background-color: $doneBg;
    // }

    // &.creator {
    //     background-color: $inactiveBg;
    // }

    &.badge-green {
        background-color: $doneBg;
    }

    &.badge-grey {
        background-color: $inactiveBg;
    }
    &.badge-red {
        background-color: $redBg2;
    }

    &.badge-small {
        padding: .05rem .4rem;

        & p {
            font-size: .7rem !important;
        }
    }
}

.defect {
    font-weight: bold;

    &.major {
        color: red;
    }

    &.minor {
        color: orange;
    }
}

.split-badge{
    border: 1px solid $text;
    border-radius: .5rem;
    padding: .2rem .4rem;
    z-index: 100;
}