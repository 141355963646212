.pdf-page{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: #e8ebed;

    &__container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & .share-card{
            width: 16rem;
            max-width: 80vh;
            background: white;
            padding: .5rem;
            border-radius: .5rem;
            margin-bottom: 1rem;
            
            display: flex;
            align-items: center;
            transition: all .3s ease;


            @include responsive('screen') {
                width: 20rem;
            }

            &:hover{
                box-shadow: rgba(113, 159, 206, 0.25) 0px 4px 15px;
            }

            & p{
                margin-left: 1rem;
                font-size: 1.2rem;
                font-weight: bold;
            }
            & .pdf-text{
                color: #B30A01;
            }
        }
    }
}

.spinner-container{
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    margin-left: auto;
}

.editor-size{
    & p{
        font-size: .85rem;
        line-height: 1.2rem;
    }
}