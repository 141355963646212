
$primary: #26633C;
$primary-hover: #164929;

$secondary: #014454;
$secondary-hover: #042e38;

$primary-clear: #557D33;

$text: #343434;

$green: #a7c139;
$green-secondary: #1a9141;
$excel: #107c41;
// $check: #74fa4e;
$check: #5ac85d;
$clear-green: #cdeece;

$silver: #efefef;
$light-grey: #d8d8d8;
$bg-grey: #F6F8FA;
$input: #dfe1ea;
$medium-grey: #80868B;
$dark-grey: #363636;
$dark-blue: #2c3848;
$text-foreground: #626f81;
$mute: #878787;

$light-green: #dff8ea;

$greenMain: #1a9141;
$white: #fff;
$black : #151515;
$danger : #C1272D;

$button-color: #1A73E8;
$button-color-disabled: #89b8f5;
$button-color-hover: #1a59ac;

$lightBlue: #dfeeff;
$lightMode: #f7f9fb;
$input-focus: #1A73E8;

/* FONTS */
$font-text: 'Roboto', sans-serif;
$font-title: 'Roboto', sans-serif;
//$font-title: 'Poppins', sans-serif;

$done: #1a9141;
$doneBg: #cdeece;
$inactive: #80868b;
$inactiveBg: #e3e4e5;
$red: #FF4D4D;
$redBg: #FFD6D6;
$redBg2: #ffe6e6;

$xsmall: 10px;
$small: 12px;
$normal: 14px;
$medium: 16px;
$large: 22px;
$xl: 28px;
$xxl: 34px;

$major: #FF4D4D;
$minor: #ff9639;


/* SCORE */
$score-0: rgb(194, 194, 194);
$score-1: #b20016;
$score-2: #ed1c24;
$score-3: #ef7100;
$score-4: #f9b233;
$score-5: #fcee21;
$score-6: #b4dd22;
$score-7: #39b54a;
$score-8: #007f2d;


// $score-1: #ed1c24;
// $score-2: #f7931e;
// $score-3: #fcee21;
// $score-4: #b4dd22;
// $score-5: #39b54a;