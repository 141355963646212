.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  cursor: pointer;

  &.toggle-small {
    width: 20px;
    height: 12px;
  }
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 24px;
  transition: 0.4s;

  &.isDefect {
    background-color: $minor;
  }
}

.toggle-slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}
.toggle-slider.toggle-small:before {
  position: absolute;
  content: '';
  height: 9px;
  width: 9px;
  left: 1.6px;
  bottom: 1.5px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

.toggle-input-two:checked+.toggle-slider {
  background-color: $check;

  &.isDefect {
    background-color: $major;
  }

}

.toggle-input-two:checked+.toggle-slider:before {
  transform: translateX(26px);
}

.toggle-input-two:checked+.toggle-slider.toggle-small:before {
  transform: translateX(7.5px);
}

//-----------------------------

// .toggle-switch {
//   position: relative;
//   display: inline-block;
//   width: 50px;
//   height: 24px;
//   cursor: pointer;
// }

// .toggle-switch input {
//   opacity: 0;
//   width: 0;
//   height: 0;
// }

// .toggle-slider {
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: #ccc;
//   border-radius: 24px;
//   transition: 0.4s;

//   &.isDefect{ background-color: $minor; }
// }

// .toggle-slider:before {
//   position: absolute;
//   content: '';
//   height: 18px;
//   width: 18px;
//   left: 3px;
//   bottom: 3px;
//   background-color: white;
//   border-radius: 50%;
//   transition: 0.4s;
// }

// .toggle-input-two:checked + .toggle-slider {
//   background-color: $check;
//   &.isDefect{ background-color: $major; }

// }

// .toggle-input-two:checked + .toggle-slider:before {
//   transform: translateX(26px);
// }