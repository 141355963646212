.fruit{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;

    &__img{
        margin: auto;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
    }

    &__title{
        text-align: center;
    }
}

.card-fruit{
    padding: .7rem 0;
    background: $white;
    box-shadow: rgba(113, 159, 206, 0.2) 0px 8px 24px;
    min-height: 100px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-intake{
    padding: 1rem 0;
    background: $white;
    box-shadow: rgba(113, 159, 206, 0.2) 0px 8px 24px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;

    @include responsive("screen"){
        width: 20rem;
    }

    & img{
        width: 2rem;
        margin-right: .3rem;
    }
    & svg{
        width: 2rem;
        margin-right: .3rem;
        fill: $primary;
    }
}

