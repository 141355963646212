.team-header{
    background-color: $dark-grey;
    padding: .1rem .5rem;
    border-radius: .5rem;

    & p{ color: white; font-size: .8rem; }
}

.team-list{
    display: flex;
    width: 100%;
    align-items: center;
    gap: .5rem;
    padding: .5rem 0;

    &:not(:last-child){
        border-bottom: .05rem solid $inactive;
    }

    &__rol{ 
        width: 3rem;
        text-align: center;
        font-size: .6rem;
        padding: .1rem 0;
        border-radius: .2rem;

        &.active-big{
            width: 6rem;
            font-size: .8rem;
            padding: .3rem .5rem;
        }
    }
    &__admin{ border: 1px solid $inactiveBg; background: $inactiveBg }
    &__user{ border: 1px solid $dark-grey; }

    &__text{
        flex:1;
        & p{ font-size: .8rem; text-align: left;}

        @include responsive('screen'){
            display: flex;
            & p:first-child{
                width: 50%;
            }
        }
    }

    &__active{ 
        width: 3.5rem;
        text-align: center;
        font-size: .6rem;
        padding: .15rem 0;
        border-radius: .2rem;

        &.active-big{
            width: 6rem;
            font-size: .8rem;
            padding: .3rem .5rem;
        }
    }

    &__active-on{
        background: $doneBg;
        color: $done;        
    }
    &__active-off{ background: $inactiveBg; color: $inactive}
}


//---------Toggle---------

