.email-btn-container{
    display: flex;
    gap: .5rem;
    align-items: center;
    justify-content: center;
}

.email-btn{
    border-radius: 5rem;
    padding: .4rem 0;
    width: 9rem;

    & p{
        font-size: .9rem;
        color: white;
    }

    &.disabled{
        background-color: $button-color-disabled;
    }

    &.blue{
        background-color: $button-color;
    }
    &.red{
        border: 1px solid #F40F02;
        p{
            font-size: .9rem;
            color: #F40F02;
        }
    }
}