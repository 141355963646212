.card-accordeon {
    background: $white;
    box-shadow: rgba(113, 159, 206, 0.2) 0px 8px 24px;
    border-radius: 12px;

    & .pallet-item {
        color: $green;
    }
}

.accordeon {
    height: auto;
    max-height: 60px;
    overflow: hidden;
    transition: all .3s cubic-bezier(0, 1, 0, 1);

    // @include responsive('screen'){
    //     height: auto ;
    //     max-height: 9999px ;
    // }
}

.accordeon.show {
    height: fit-content;
    max-height: 9999px;
    transition: all .3s cubic-bezier(0, 1, 0, 1);
}

.accordeon-edit {
    cursor: pointer;
}

.accordeon-pallet {
    display: flex;
    align-items: center;
}

.accordeon-button {
    width: 100%;
    display: flex;
    justify-content: space-between;

    & img {
        width: 1rem;

        // @include responsive('screen'){
        //     display: none;
        // }
    }

    .chevron-up {
        transform: rotate(0);
        transition: transform .2s ease;
    }

    .chevron-down {
        transform: rotate(180deg);
        transition: transform .2s ease;
    }
}

.accordeon-table {
    position: relative;
}

.accordeon-edit {
    width: 1rem;
    background: none;
    position: absolute;
    top: 8px;
    right: 5px;

    &.custom-edit {
        top: 0;
        bottom: -45px;
    }

    &.custom-edit-report {
        top: -8px;
    }
}

.accordion-card {
    background: $white;
    box-shadow: rgba(113, 159, 206, 0.2) 0px 8px 24px;
    border-radius: 12px;

    width: 100%;
    transition: all 0.3s ease;
    // overflow: hidden;

    .card-header {
        width: 100%;
        padding: 1.2rem 1rem;
    }

    .card-content {
        // padding: 0 1rem;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease;

        &.container-card, & .container-card{
            padding: 0 1rem;
        }
    }

    &.active {
        .card-content {
            max-height: 10000px;
        }
    }
}

.accordion-contact {
    width: 100%;
    transition: all 0.3s ease;
    // overflow: hidden;

    .card-header {
        width: 100%;
    }

    .card-content {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease;
    }

    &.active {
        .card-content {
            max-height: 10000px;
        }
    }
}

.pallet-step{
    padding: 0 1rem;

    &.active-step{
        background: $bg-grey;
        border: 1px solid $greenMain;
        border-radius: .8rem;
        padding: 1rem;
    }
}

.accordion-open-btn{
    display: grid;
    place-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: .5rem;
    cursor: pointer;
    position: relative;

    &.blue{
        background-color: $button-color;
    }
}