.image-selected-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: .6rem;

    @include responsive('screen') {
        grid-template-columns: repeat(5, 1fr);
        gap: 1rem;
    }

    &.image-grid-full {
        @include responsive('screen') {
            grid-template-columns: repeat(10, 1fr);
            gap: .6rem;
        }
    }

    & .selected-container {
        position: relative;
    }

    & .selected-image {
        overflow: hidden;
        // margin: .2rem;
        border-radius: .5rem;
        aspect-ratio: 1;

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    & .remove-img-btn {
        position: absolute;
        top: -.45rem;
        right: -.45rem;
        background-color: black;
        width: 1.2rem;
        height: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .3rem;
        border-radius: 50%;

        // @include responsive('screen'){
        //     top: -.7rem;
        //     right:  -.7rem;
        //     width: 1.6rem;
        //     height: 1.6rem;
        // }
    }
}


.grid-gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    place-items: center;

    @include responsive('screen') {
        grid-template-columns: repeat(5, 1fr);
        grid-auto-rows: 1fr;
    }

    &.gallery-full {
        @include responsive('screen') {
            grid-template-columns: repeat(10, 1fr);
            grid-auto-rows: 1fr;
        }
    }


    &__box {
        width: 100%;
        aspect-ratio: 1;
        cursor: pointer;
        overflow: hidden;

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    //---Temporal Images---


    & .selected-container {
        position: relative;
        margin-top: 7%;
        margin-right: 7%;
        width: 70%;
        aspect-ratio: 1;
        // margin: .4rem;

        @include responsive('screen') {
            width: 75%;
        }
    }

    & .selected-image {
        overflow: hidden;
        // margin: .2rem;
        border-radius: .5rem;
        aspect-ratio: 1;

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    & .remove-img-btn {
        position: absolute;
        top: -.5rem;
        right: -.5rem;
        padding: .35rem;
        background-color: black;
        width: 1.2rem;
        height: 1.2rem;
        display: grid;
        place-items: center;
        border-radius: 50%;
    
    }
    // & .remove-img-btn {
    //     position: absolute;
    //     top: -.5rem;
    //     right: -.5rem;
    //     background-color: white;
    //     width: 1.2rem;
    //     height: 1.2rem;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
        
    //     border-radius: 50%;
    // }
}